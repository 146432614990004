
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
 }
 
 .image-container {
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-size: cover;
 }

 .image-container-autoimage
 {
  position: relative;
  width: 100%;
  height: 470px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-size: cover;
 }
 .image-container-autoimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
 }
 
 .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
 }
 
 .caption, .text1, .text3 {
  align-items: center;
  justify-content: left;
  color: #425563;
  padding-left: 50px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  // font-family: 'museo100';
  padding: 8px;
 }
 
 .caption {
  margin-bottom: 260px;
  padding-left: 40px;
  font-size: 45px;
  font-family: 'Museo Sans 100 Regular';
  font-weight: 700;
 }
 
 .text1 {
  margin-bottom: 235px;
  font-size: 38px;
  width: 50vw;
  line-height: 1.1;
  text-align: left;
  overflow-wrap: break-word;
  padding-left: 40px;
  font-weight: 500;
  font-family: "Museo Sans 100 Regular";
 }
 
 .text3 {
  margin-bottom: 180px;
  font-size: 25px;
  padding-left: 40px;
  font-weight: 700;
  font-family: "Museo Sans 100 Regular";
 }
 
 .text3:hover {
  scale: 1.1;
  transition: ease-in;
 }
 
 .image-container:hover .caption {
  opacity: 1;
 }
 
 .dots-container {
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
 }
 .text-wrap {
  white-space: wrap;
  max-width: 50%; 
  text-align: left;
 }
 


 /* Add media queries for responsiveness */
@media (max-width: 768px) {
  .caption, .text1, .text3 {
     padding-left: 20px; 
     font-size: 18px; 
  }
  .text-wrap
  {
    max-width: 100%; 
  }
 
  .caption {
     margin-bottom: 150px; 
  }
 
  .text1 {
     margin-bottom: 100px; 
  }
 
  .text3 {
     margin-bottom: 80px; 
  }
 }
  
  /* Style for the black dot */
/* Style for the black dot */
/* Style for the black dot */
.dot {
  position: relative;
  display: inline-block;
  width: 15px; /* Increased size for the outer black dot */
  height: 15px; /* Increased size for the outer black dot */
  margin: 0 5px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Style for the active black dot */
.dot.active {
  background: white;
}

/* Style for the dot's inner white circle */
.dot::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px; /* Size of the inner white dot */
  height: 5px; /* Size of the inner white dot */
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Style for the black dot's inner black circle (using ::after) */
.dot.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3px; /* Size of the inner black dot */
  height: 3px; /* Size of the inner black dot */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: black;
}
