.carousel-container {
    height: 200px; /* Adjust the height according to your image dimensions */
    overflow: hidden;
    align-items: center;
    justify-content: center;
    // gap: 0px 60px;
    // padding-left: 50px;
    // padding-right: 50px;
    
  }

  .carousel-container {
    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .slick-slider {
    text-align: center;
     /* Center the images within the slider */
  }
  
  // .slick-slide {
  //   padding: 0 100px;
    
  //   /* Add horizontal padding between slides */
  // }
  
  .carousel-container img {
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease-in-out; 
    box-shadow:rgba(0,0,0,.24) 0 3px 8px;
    padding:5px;
    margin: 5px;
  }
  
  @media (min-width: 1024px) {
    .slick-slide {
      padding: 0 10px; /* Increase horizontal padding between slides */
    }
  }
  
  /* Adjust for screens with a width of 1440px and higher */
  @media (min-width: 1440px) {
    .carousel-container {
      height: 200px; /* Adjust the height for larger screens */
    }
    .slick-slide {
      padding: 0 10px; /* Further increase horizontal padding between slides */
    }
  }
  @media (min-width: 1920px) {
    .carousel-container {
      height: 270px; /* Adjust the height for larger screens */
    }
    .slick-slide {
      padding: 0 15px; /* Further increase horizontal padding between slides */
    }
  }
  @media (min-width: 1503px) and (max-width:845px)
  {
    .carousel-container {
      height: 250px; /* Adjust the height according to your image dimensions */
      overflow: hidden;
      align-items: center;
      justify-content: center;
      gap: 0px 10px;
      padding-left: 50px;
      padding-right: 50px;
      
    }
    .slick-slider {
      text-align: center;
       /* Center the images within the slider */
    }
    
    .slick-slide {
      padding: 0 50px;
      
      /* Add horizontal padding between slides */
    }
  }