/*===========================
--. Footer Style CSS
===========================*/
.footer {
  background-color:#b23427;
 justify-content: space-between;
 border-top: 2px solid white;
 padding-left: 20px;
 padding-right: 20px;
}
.row1
{
border-bottom: 1px;
color:white;
}
.row1 li 
{
  display: flex;
  justify-content: end;
   margin-top: -50px;
  gap: 30px;
  font-size: 20px;
  color:white;
}
.youtube:hover
{
  transform: scale(1.5);
}
.whatsapp:hover
{
  transform: scale(1.5);
}
.linkedin:hover
{
  transform: scale(1.5);
}
.MuiInputBase-input::placeholder {
  color: white;
}

.row3
{
  color: #fff;
   font-family: "HelveticaNeue-Roman",arial,helvetica,sans-serif;
 font-size: 16px;
  border-top: 1px solid white;
;
}




.footer-quicklist
{
  color: #fff;
    font-family: "HelveticaNeue-Roman",arial,helvetica,sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .6px;
    line-height: inherit;
    text-decoration: none;
    padding: 1px 1px; /* Adjust the padding as needed */
    gap: 30px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.footer-quicklist li a:hover
{
text-decoration: underline;
}

.row {
  flex-basis: 30%; /* Adjust this value based on your design */
  padding: 20px;
}
