@media (max-width: 768px) {
.row
{
    padding-left: 0px !important;
    padding-right: 0px !important ;
}
.Breadtext
{
    padding-top: 0px !important;
}
.mobiletext
{
    font-size: 20px !important;
    width: 100% !important;
}
.searchmobile
{
    padding-left: 20px !important;
}
.breadtextmobile
{
    font-size: 14px !important;
}
.maintext
{
    padding: 3px !important;
}
.basetext
{
    padding-right: 0px !important; 
}
.shutertext
{
    display: flex !important;
    flex-direction: column !important;
}
.pagescroll
{
    max-width:100%;
}

}

.sp-x
{
    padding: 106px 0px !important;
}

.sidebar-list
{
    border-radius: 10px;
    height: 90vh !important;
    overflow-y: scroll !important;
}
.checkboxli
{
    display: flex !important;
    align-items: flex-start;
}

.loader-container
{
    
    height: 500px !important;
    width: 100% !important;
   display: flex;
   align-items: center;
   justify-content: center;
}
.loader-container1
{
    
    height: 100px !important;
    width: 100% !important;
   display: flex;
   align-items: center;
   justify-content: center;
}

