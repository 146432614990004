.ContactUs1 {
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.hover-effect {
  font-family: "Garamond, serif";
  font-size: 43px;
  color: brown;
  padding-left: 70px;
  padding-top: 50px;
}

.hover-effect:hover {
  color: black;
  font-family: "Garamond, serif";
  font-size: 43px;
}
.sidebar {
  margin-top: 30px;
  width: 200px;
  background-color: white;
  position: relative;
  overflow: hidden;
  margin-left: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  /* Add position: relative; to the .list container */
}

.list-item {
  padding: 10px 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative; /* Add position: relative; to the list items */
}
.image-container
{
  height: 320px;
}
.red-line {
  position: absolute;
  width: 4px;
  height: 70px;
  padding: 1px 1px;
  background-color: brown;
  top: 0;
  right: 0px; /* Add right: 100px; to position the red line */
  transition: top 0.3s;
}
.form-container {
  width: 80%;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: white;
  margin-left: 230px;
}

.hover-effect {
  font-family: "Garamond, serif";
  font-size: 43px;
  color: brown;
}

.hover-effect:hover {
  color: black;
  font-family: "Garamond, serif";
  font-size: 43px;
}

.form-input-container {
  display: flex;
  gap: 10px;
}

.Form1placeholder {
  width: 100%;
  height: "50px";
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 25px;
}

.Form2placeholder {
  width: 100%;
  height: "50px";
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 25px;
}

.form-input1 {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.form-input2 {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}
.form-input3 {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}
.form-input4 {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}
.form-input5 {
  border-radius: 15px;
}

.error {
  color: #dc3232;
  font-size: 17px;
  margin-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  justify-content: center;
}

.form-button {
  background-color: brown;
  color: #fff;
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
}

.form-button:hover {
  background-color: white;
  background: black;
  border: 2px solid black;
}

.procontainer
{
  font-size: 40px !important;
  margin-top: 2rem !important;
  padding-bottom: 1rem !important;
}
/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .form-container {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sidebar {
    margin-left: 0px;
    width: auto;
  }
}

/* Media queries for medium screens (between 601px and 1024px) */
@media (min-width: 601px) and (max-width: 1024px) {
  .form-container {
    max-width: 80%;
  }
}

/* Media queries for large screens (greater than 1024px) */
@media (min-width: 1025px) {
  .form-container {
    max-width: 70%;
  }
  .sidebar {
    margin-left: 30px;
  }
}
@media (max-width: 768px) {
  .form-container {
    margin-left: 0px !important;
  }
  .form-input5 {
    width: 100% !important;
  }
  .Form1placeholder {
    display: flex !important;
    flex-direction: column !important;
  }
  .form-input1 {
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
  .form-input2 {
    width: 100% !important;
  }
  .Form2placeholder {
    display: flex !important;
    flex-direction: column !important;
  }
  .form-input3 {
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
  .form-input4 {
    width: 100% !important;
  }
  .data-container{
    padding-left: 0px !important;
    margin-top: 0px !important;
  }
  .data-container iframe{
    margin-left: 0px !important;
  }
  .selected-data p{
    margin-left: 5% !important;
  }
  .ContactUs1 h1:first-child{
    padding-left: 0px !important;
  }
  .promobile
  {
    display: flex !important;
    flex-direction: column !important;
   align-items: center !important;
   
  }
  /* .barimage
  {
    height: 85px !important;
  } */
  .profileimgmobile
  {
    margin: 30px !important;
    
  }
  .image-container2
  {
    height: auto;
  }
  .promobile1
  {
    display: block !important;
    margin-left: 0px !important;
    text-align: center !important;
  }
  .faqtext 
  {
    display: flex;
    flex-direction: column !important;
    height: auto !important;
    gap: 20px !important;
    align-items: center !important;
  }
  .faqtextarea
  {
    width: 100% !important;
    padding: 20px 20px !important;
  }
  .buttonfaq
  {
    width: 95% !important;
  }
  .buttonfaq1
  {
    width: 95% !important;
    margin-left: 0px !important;
  }
  .faqmaintext
  {
    font-size: 30px !important;
    margin-top: 20px !important;
  }
  .faqtrade
  {
    display: flex;
    flex-direction: column !important;
    height: auto !important;
    gap: 20px !important;
    align-items: center !important;
  }
  .privacycontainer
  {
    font-size: 35px !important;
  margin-top: 0.5rem !important;
  padding-bottom: 1rem !important;
  }
  .procontainer1
  {
    display: flex;
    flex-direction: column !important;
    margin-top: 45px !important;
  }
  .procontainer2
  {
    display: flex;
    flex-direction: column !important;
    margin-left: 105px !important;
  }
  .PorHead
  {
    margin-top: 30px !important;
  }
  .proheading
  {
    font-size: 40px !important;
   
  }
  .protitle
  {
    margin-top: 20px !important;
  }
  .protitle1
  {
font-size: 20px !important;
  }
  .termhead
  {
    margin-top: 30px !important;
  }
 .termsubhead
 {
  padding-bottom: 10px !important;
 }


}
