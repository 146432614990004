.wrappersearch {
    margin-left: auto;
    margin-right: auto;
    max-width: 56rem;
    /* border-radius: 1%; */ 
  }
  .maindiv
  {
      background-color: rgb(246, 246, 246);
      padding: 2rem; 
  }
  /* search section  */
  .search_section {
    /* padding: 10px;
    border: 1px dotted red;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1); */
    position: relative;
    width: 100%;
    background-color: white;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
     /* box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5); */
    padding: 16px;
    border-bottom: 1px solid rgb(198, 195, 195);
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  .trade
  {
    margin-bottom: 0px !important;
  }
  
  .content h1 {
    font-size: 1.875rem;
    font-weight: 500;
  }
  
  .content p {
    font-size: 16px;
    opacity: .9;
  }
  
  .content {
    margin-bottom: 20px;
  }
  
  .search_form {
    display: flex;
    justify-content: space-between;
    align-content: center;
    gap: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .inputfield {
    width: 80%;
  }
  
  .inputfield input {
    width: 100%;
    padding: 12px;
  }

  
  .search_btn {
    width: 20%;
  }
  
  .search_btn button {
    width: 100%;
    color: white;
    background-color: rgb(178, 52, 39);
  }

  .moredetails_btn {
    width: 10rem;
    height:2.5rem;
    color: white;
    background-color: rgb(178, 52, 39);
    border-radius: 10px;
  }
  
  /* Filter section */
  .filter-section {
    padding: 0.5px;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5); */
    background-color: white;

  }
  
  .reg_btn {
    margin: 20px 16px;
  }
  
  .reg_btn button {
    border-radius: 50px;
    padding: 2px 65px;
    background-color: rgb(178, 52, 39);
    color: white;
    width: auto;
    height: 2.5rem;
    font-size: 14px;
  }
  
  .filter_label {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;
    padding: 16px;
  }
  
  .select-wrapper {
    opacity: .9;
    width:5rem;
    border: none !important;;
  }
  .no-border .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  
  .select-label {
    margin: 0 10px;
    text-decoration: underline;
    padding-bottom: 4px;
    line-height: 1;
  }
  
  /* output section Trademark Results */
  
  .trademark_section {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    /* box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5); */
    background-color: white;
    position: relative;
    
  }
  
  .content {
    padding: 10px;
  }
  
  .text {
    opacity: .9;
  }
  
  .txt {
    font-weight: 600;
  }
  
  .Generate_pdf a {
    color: rgb(178, 52, 39);
    text-decoration: none;
  }
  
  .Generate_pdf a:hover {
    color: darkred;
  }
  
  .class1 {
    text-align: center;
    width: 110px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgb(178, 52, 39);
    color: #fff;
    padding: 1px, 20px;
    text-transform: uppercase;
  }
  
  .banner {
    width: 100%;
    height: 300px; 
    background-image: url('../assets/img//blog/inner_banner.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}

.banner-content {
    text-align: left;
    color: white;
    margin-left: 40px;
    font-size: 24px;
    font-family:"museo500";
    /* font-weight: 400; */
}
  /* api output section start */
  
  .output_section {
    border-top: 0.5px solid rgb(107 114 128);
    padding: 20px;
    /* box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5); */
    background-color: white;
  }
  
  .trademark-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .border-line {
    border: 1px solid #000;
    margin: 20px 0;
  }

  .Generate_pdf :hover
  {
    background-color: white !important;
  color: #b23427;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
  {
    border-radius: 0px !important;
  }
  @media only screen and (max-width: 450px) {
    .maindiv {
      padding: 0.5rem;
    }
  }