.product-carousel {
    position: relative;
    overflow: hidden;
    padding: 26px;
}

.product-container {
    padding: 0 10px;
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.product-carousel .slick-slide {
    width: 100%; 
    display: flex; 
    justify-content: space-around; 
   }
   
   .product-carousel .slick-slide > div {
    flex: 1; 
    margin: 0 10px; 
}
.pre-btn,
.next-btn {
    border: none;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
}

.next-btn {
    right: 0;
}

.next-btn p,
.pre-btn p {
    font-size: 50px;
    background-color: rgba(255, 255, 255, 0.436);
    box-shadow: 0px 0px 10px 1px black;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    width: 50px;
    height: 50px;
    cursor: pointer;
}

@media (max-width: 960px) { /* Adjust the breakpoint as needed */
    .product-carousel {
       /* Adjust the size or layout of the Mycard component for tablets */
       width: 100%; /* Example adjustment */
       padding: 10px; /* Example adjustment */
    }
   }









