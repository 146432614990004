/* App.css */
/* App.css */
/* App.css */
/* App.css */

@media (max-width: 768px) {
  .outterdivcircle {
    flex-direction: column;
    align-items: center;
  }

  .circlestyle {
    position: static;
    transform: none;
    margin: 0 auto;
    text-align: center;
  }
  .circlestyle h3 {
    margin-top: 0px !important;
    margin-left: 0px !important;
   
  }
  .spacingmobile
  {
    width: 100% !important;
  }
  .peoplemobile
  {
    margin: 0px !important;
    padding-inline: 20px !important;
    font-size: 16px !important;
    line-height: 30px !important;
    font-family: HelveticaNeue-Roman, arial, helvetica, sans-serif !important;
  }
  .profile-image1
  {
    width: 100% !important;
  }
  .peoplehome
  {
    margin-left: 20px !important;
  }
  .peopledesi
  {
    margin-left: 20px !important;
    margin-top: 20px !important;
  }
  .peopleshare
  {
    margin-left: 20px !important;
  }
  .peopleloc
  {
    margin-left: 20px !important;
  }
}
.People {
  text-align: center;
  padding: 20px 5%;

}

.alphabets {
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* Remove underline by default */
  display: inline-block;
  padding: 2px;
  text-align: center;
  /* Add transition for smooth animation */
}

.alphabets:hover {
  padding: 1px;
  align-items: center;
  justify-content: center;
  background-color: #e7eaeb;
  border: 1px solid #d0d5d8;
  color: #b23427;
  /* text-decoration: underline 2px solid #b23427; */
  /* Underline on hover */

  /* Change text color to #d0d5d8 on hover */
}

.employee-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 0 10%;
}



.employee-card {
  background-color: #E7EAEB !important;
  border: 1px solid #ccc;
  margin: 10px;
  max-width: calc(100% - 20px);
  text-align: center;
}

.employee-card:hover {
  transform: scale(1.01);
  /* Enlarge the card on hover */
}

.spacer {
  flex-basis: 100%;
  /* Force a new row */
  height: 0;
  /* Hide the spacer */
}

.select {
  background-color: white;
  cursor: pointer;
  /* border: 1px solid #b23427 !important; */
}

.select:hover {
  color: white !important;
  background-color: #b23427 !important;
}

.formControl:hover>.select-label {
  color: white !important;
}

.formControl {
  text-align: left;
}

.alphabets-active {
  background-color: #e7eaeb;
  border: 1px solid #d0d5d8;
  color:"#425563";
  text-decoration: underline 2px solid #b23427;
}
.alphabets:hover {
  background-color: #e7eaeb;
  border: 1px solid #d0d5d8;
  color:"#425563";
  text-decoration: underline 2px solid #b23427;
}

button {
  padding: 10px 20px;
  background-color: white;
  color: #b23427;
  border: 2px solid #b23427;
  cursor: pointer;
  width: 200px;
  height: 50px;
}

button:hover {
  background-color: #b23427;
  color: white;
  /* border: 2px solid #b23427; */
}

.Arrow:hover {
  color: white !important;
}

/* Apply styles for the focused state of the FormControl */
/* Apply styles for the focused state of the FormControl */
/* Apply styles for the focused state of the FormControl */
/* Apply styles for the focused state of the FormControl */
.formControl.focused {
  outline: none !important;
  border-radius: 0px;
  
}

/* Apply styles for the input inside the FormControl when focused */
.formControl.focused .MuiOutlinedInput-input {
  padding: 14px;
  border: none !important;
  background-color: transparent;
  color: #b23427;
}
.formControl.focused .MuiOutlinedInput-input:hover
 {
  background-color: #b23427 !important;
  color: white !important;
}

/* Apply styles for the label inside the FormControl when focused */
.formControl.focused .MuiInputLabel-root {
  color: #b23427 !important;
}

/* Apply styles for the input underline when focused */
.formControl.focused .MuiOutlinedInput-notchedOutline {
  border-color: #b23427 !important;
 
}

/* Apply styles for the input underline on hover */
.formControl.focused:hover .MuiOutlinedInput-notchedOutline {
  border-color: #b23427 !important;
}

/* Apply styles for the input underline when focused and shrink */
.formControl.focused .MuiInputLabel-shrink {
  color: #b23427 !important;
}

/* Apply styles for text and form triangle on hover */
.formControl.focused:hover .MuiSelect-icon,
.formControl.focused:hover .MuiListItemText-primary {
  color: white !important;
  background-color: #b23427 !important;
}
.formControl:hover .MuiOutlinedInput-input {
  color: #b23427; /* Set text color to red on hover */
}


/* Apply styles for text and form triangle on hover */
.formControl:hover .MuiSelect-icon,
.formControl:hover .MuiListItemText-primary {
  color: #b23427 !important;
}
/* .MuiIcon,
.MuiSvgIcon-root {
  color: #b23427 !important;
} */
.formControl .MuiSelect-icon {
  color: #b23427 !important;
}
/* Apply styles for the formControl when focused or clicked */
/* Apply styles for the formControl when focused or clicked */

