
.main_container {
    background-color: rgb(246, 246, 246);
    /* padding-top: 2rem;
    padding-bottom: 18rem; */
    padding: 2rem;


  }
  
  .wrapperpatent {
    box-sizing: border-box;
    /* background-color: #fff; */
    max-width: 56rem;
    margin: 0 auto;
  
  }
  
  .search_container {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12),
      0px 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #fff;
    border-radius: 7px;
  }
  
  .patent_search {
    padding: 20px;
  
  }
  
  .content {
    padding: 0 !important;
  }
  
  .content h3 {
    line-height: 18px;
    font-weight: 400;
    font-size: 1.875rem !important;
    font-family:  "Helvetica", "Arial", "sans-serif";
  }
  
  .content span {
    color: rgba(0, 0, 0, .54);
    font-weight: 400;
  }
  
  .search_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
  }
  
  .title {
    width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #000;
    height: 40px;
  }
  
  .title select {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .input {
    width: 60%;
  }
  
  .input input {
    width: 100%;
    height: 40px;
  }
  
  .searchbtn {
    width: 20%;
    color: #000 !important;
  }
  
  .searchbtn button {
    width: 100%;
    background-color: #b23427;
    color: white;
    font-size: 14px;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  /* result section */
  
  .result_container {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12),
      0px 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #fff;
    border-radius: 7px;
  }
  
  .search_Results {
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  
  .result_section {
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 25px;
  
  }
  
  .results {
    margin-left: 10px;
    /* border-bottom: 1px solid #ccc; */
  }
  
  .patent-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    overflow: scroll;
  }
  
  .patent_title {
    font-weight: 600;
  }
  
  span {
    /* font-weight: 400; */
    /* opacity: .9; */
  }
  
  .status {
    border: 1px solid rgb(168, 165, 165);
    /* padding: 1px; */
    color: #000;
    font-size: 14px;
    /* padding: 0.5rem; */
    font-weight: 500;
    height: 1.5rem;
    width: 5.2rem;
    text-align: center;
    vertical-align: middle;
  }
  
  /* .status {
    background-color: #28a745;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  } */
  
  .patent-divider {
    margin: 20px 0;
  }
.parent-btn
{
    max-height: 1.8rem;
    min-width: 64px;
    border: 1px solid #b23427 !important;
    background-color: #b23427;
    color: whitesmoke;
}

.parent-btn:hover
{
    background-color: #b23427 !important; 
    filter: drop-shadow(5px 5px 8px gray);
}

.detail-btn
  {
    border: 1px solid #b23427 !important;
    max-height: 1.8rem;
    min-width: 64px;
  }
  .detail-btn:hover
  {
   background-color: #fbfbfbdf;
   filter: drop-shadow(5px 5px 8px gray);
   color:#b23427;
  }
  
  .status_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .status_link .patent-button {
    padding: 10px;
    border: 1px solid #b23427;
    color: #b23427;
  }
  
  .status_link .status-button {
    padding: 10px;
    background-color: #b23427;
    color: #fff;
  }
  
  
  .api-details {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    overflow: auto;
    max-height: 200px;
  
  }
  
  .api-details p {
    margin-bottom: 5px;
  }
  
  .api-details pre {
    font-size: 14px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .slide-panel {
    position: fixed;
    top: 0;
    right: -65%;
    width: 60%;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
    margin: 5px, 20px;
    padding: 20px;
  }
  
  .slide-panel.open {
    right: 0;
    transform: translateX(0);
  }
  
  .slide-panel .close-btn {
    display: block;
    text-align: right;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  /* .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  } */
  
  .download-link {
    color: #b23427;
    text-decoration: none;
    cursor: pointer;
  }
  
  .link_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .link_heading a,
  .link_heading2 a {
    color: black;
    text-decoration: none;
    padding: 10px;
    display: inline-block;
    transition: margin 0.3s ease;
  }
  
  .link_heading a.active,
  .link_heading2 a.active {
    color: #b23427;
    font-weight: bold;
    text-decoration: underline;
    margin-right: 5px;
    transition: margin 0.3s ease;
  }
  
  .link_heading2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 1000;
  }
  
  .overlay.open {
    display: block;
  }
  
  /* table */
  .custom-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    text-align: left;
    background-color: #f2f2f2;
  }
  
  
  .panel-content {
    width: 100%;
    max-height: 100%;
  
  }
  
  .patent-details-container {
    width: 100%;
    /* margin: 10px; */
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  /* StatusComponent.css */
  
  /* StatusComponent.css */
  
  .flex {
    display: flex;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .space-x-8>*:not(:last-child) {
    margin-right: 2rem;
  }
  
  .justify-around {
    justify-content: space-around;
  }
  
  .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .items-center {
    align-items: center;
  }
  
  .relative {
    position: relative;
  }
  
  /* .status {
    padding: 0.5rem;
    color: black;
    font-weight: 500;
    height: 2rem;
    text-align: center;
    vertical-align: middle;
  } */
  
  .path-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .path-container svg {
    position: absolute;
    pointer-events: none;
  }
  
  .path-1 {
    left: -391.06px;
    top: -8px;
  }
  .add_field_button :hover
  {
  background-color: white !important;
  color: #b23427;
  }
  .remove_field_button :hover
  {
  background-color: white !important;
  color: #b23427;
  }
  .Toastify__toast {
    width: 100% !important;
  }
  .Toastify__close-button {
    width: 20%; 
    max-width: 20px; 
    background-color: transparent !important;
  }

  .Toastify__close-button:hover {
    background-color: none !important; 
    color: gray !important;;
  }

  @media (max-width: 450px) {
    .main_container{
      padding: 10px !important;
    }
    .patent_search
    {
      padding: 12px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .slide-panel
    {
      width: 100% !important;
    }
  }
  .MuiTypography-colorPrimary
  {
    color:#b23427 !important;
  }
  .innerloader::after
  {
   color:#000 !important;
  }