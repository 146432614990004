/*=====================
14. Blog Style CSS
======================*/

.blog-item {
  background-color:white;
  transition: 0.4s;
  margin-top: 5px;
  border-bottom: 1px solid $borderColor;

  .blog-content {
    padding: 50px 30px 45px;
    margin-top: -60px;


    @media #{$sm-device} {
      padding: 30px 15px 25px;
    }

    h2 {
      font-family: Garamond serif;
      font-size: 24.225px;
      margin-bottom: 30px;

      a {
        color:#425563;

        &:hover {
          color: #b23427;
          text-decoration: underline 2px solid #b23427;
        }
      }
    }

    .blog-meta {
      display: flex;
      // justify-content: space-between;
      margin-top: 20px;

      a {
        color: $paragraph;
        font-weight: 400;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        @media #{$xs-device} {
          font-size: 14px;
        }
      }
    }
  }

  // &:hover {
  //   box-shadow: 0 10px 60px 0 rgba(71, 74, 182, 0.16);
  //   transform: translateY(-10px);
  // }
}