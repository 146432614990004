.parent-container .placeholder-text-color .MuiInputBase-root {
  border-bottom: 2px solid #b23427 !important;
  color: #b23427 !important;
}

.parent-container .placeholder-text-color .MuiInput-underline::before {
  border-bottom: 2px solid #b23427 !important;
  color: #b23427 !important;
}

.parent-container .placeholder-text-color .MuiInput-underline::after {
  border-bottom: 2px solid #b23427 !important;
  color: #b23427;
}

.parent-container .borderline1 {
  border-bottom: 1px solid #e7eaeb !important;
}

.parent-container .borderline2 {
  border-bottom: 1px solid #e7eaeb !important;
}

.placeholder-text-color ::placeholder {
  color: #425563 !important;
  -webkit-font-smoothing: antialiased;
  opacity: 1;
}
.mobhamburger{
  display: none;
  color: black;
}
@media (max-width: 768px) {
  .mobilelogo
  {
    width: 50% !important
  }
  }
  @media (max-width: 899px) {
  .mobhamburger
    {
      display: block;
      color: black;
    }
  
  }

