/*==========================
04. Index Area Style CSS
===========================*/

.header-area{
  background-color:white;
  transition: 0.4s;
  &.sticky{
    animation: slideInDown 0.6s forwards;
    padding: 1px 0 1px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    @media #{$sm-device, $md-device} {
      padding: 20px 0;
    }

    .logo{
      &-dark{
        display: none;
      }
    }

    .main-menu{
      .submenu-nav{
        margin-top: 25px;
        &:before{
          height: 25px;
        }
      }
    }
  }

  &.transparent:not(.sticky){
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .logo{
      &-light{
        display: none;
      }
    }

    .main-menu{
      & > li{

        &.has-submenu{
          &:after{
            color: black;
            font-weight: 600;
       
          }
         
        }

        
        & > a{
          color: black;
          font-weight: 600;
         
        }
        
        
      }
    }

    .header-action{
      a,button{
        color: black;
        font-weight: 600;
        display: flex;
      }
    }
  }
}

// Index Action
.header-action{
  color: black;
  font-size: 22px;

  @media #{$lg-device, $sm-device} {
    font-size: 20px;
  }

  a{
    color: $white;

    &.tel-no{
      @media #{$xs-device} {
        display: none;
      }
    }
  }

  [class*='btn-']{
    color: black;
    margin-left: 20px;
  }
}