
/* CSS For Strapi Data  */
ul > li{
  list-style-type: inherit !important;
}

tbody td {
  border: 1px solid !important;
}

.headingtext h2 {
  margin-bottom: 10px !important;
}

.circlestyle {
  width: clamp(250px, 30vw, 370px);
  height: clamp(250px, 30vw, 370px);
  border-radius: 50%;
  background-color: #b23427;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(50%, 10%);
  z-index: 1;
  transition: 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}
.circlestyle:hover {
  scale: 1.03;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.mobileimage {
  width: 100% !important;
}
.descriptionDiv a:hover
{
  text-decoration: underline;
  color: blue !important;
}
@media (max-width: 768px) {
  /* Adjust styles for mobile view */
  .titleabout {
    flex-direction: column;
  }
  .image-container {
    order: 1;
  }
}

@media (max-width: 768px) {
  .outterdivcircle {
    flex-direction: column;
    align-items: center;
  }

  .circlestyle {
    position: static;
    transform: none;
    margin: 0 auto;
    text-align: center;
  }
  .circlestyle h3 {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .mobilebanner {
    margin: 30px 0px !important;
  }
  .viewmorebutton {
    margin-top: 3rem !important;
  }
}

@media (max-width: 768px) {
  .expertisemobile {
    height: auto !important;
    padding-bottom: 4.5rem !important;
  }
  .readmoremobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .mobilereadbuttton {
    margin-left: 0px !important;
  }
  br.readmoremobile {
    display: none;
  }

  h3 {
    paddingtop: "10px";
    fontsize: "40px";
  }
  .mobilereleated {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  button {
    margin-bottom: 10px;
  }
  .social-icon {
    border-radius: 50% !important;
  }
 
}

@media (max-width: 768px) {
  .mobileimage {
    margin-left: 0rem !important;
    width: 100% !important;
  }
  .Imagemobile {
    width: 90% !important;
    margin-bottom: 2rem !important;
  }
  .profile-caption {
    margin: 0 auto !important;
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 20px !important;
  }
  .Headingtext
  {
    font-size: 19px !important;
  }
  .newstext
  {
    font-size: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
   
  }
  .newsleft
  {
    margin-left: 0px !important;
  }
  .experttext
  {
    font-size:10px !important;
    margin-bottom: 1rem !important;
    margin-left: 10px !important;
  }
  .Explormobile
  {
    display: flex;
    text-align: left !important;
    justify-content: left !important;
   
  }
  .listtext
  {
    font-size: 16px !important;
  }
  .image-container {
    height: 300px !important;
  }
}

@media (max-width: 768px) {
  .newsmobile {
    margin-left: 0px !important;
    margin-top: 2rem !important;
  }
  .globalmobile {
    min-width: 10rem !important;
    font-size: 20px !important;
    display: flex !important;
    justify-content: center !important;
  }
  .viewmoredetails {
    margin-left: 0px !important;
    justify-content: space-evenly !important;
    width: 100% !important;
  }
  .cardblogmobile {
    width: 100% !important;
  }
}

p strong
{
  display: inline !important;
  width: fit-content !important;
}
