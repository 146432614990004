@media (max-width: 768px) {
    .outterdivcircle {
      flex-direction: column;
      align-items: center;
    }
  
    .circlestyle {
      position: static;
      transform: none;
      margin: 0 auto;
      text-align: center;
    }
    .circlestyle h3 {
      margin-top: 0px !important;
      margin-left: 0px !important;
    }
    .peopleshort {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .peopleshort p {
      margin: 0px !important;
      padding-inline: 20px !important;
      max-width: 100%; /* 20px padding left and right */
    }
}


