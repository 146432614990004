.linear-loader {
    height: 4px;
    width: 100%;
    background-color: #f0f0f0;
    position: relative;
  }
  
  .linear-loader::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #b23427; 
    animation: loading 2s linear infinite;
  
  }
  
  @keyframes loading {
    0% {
      width: 0;
    }
  
    100% {
      width: 100%;
  
    }
  }