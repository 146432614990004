.wrapper {
  padding: 2rem;
   box-sizing: border-box;
   margin: 0 auto;
   max-width: 45rem;
 }

h1 {
  font-size: 1.5rem;
  font-weight: 400;
}

.applicant_deatils_section {
  padding: 30px 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5);
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 25px;
  position: relative;
}

.applicant_details_section form {
  display: flex;
  flex-direction: column;
}

.btn_list1 {
  position: absolute;
  top: -3.5%;
  left: 3%;
}

.btn_list1 button {
  background-color: #b23427;
  color: white;
  border-radius: 50px;
  padding: 1px 10px;
}

.applicant_detail input {
  width: 70%;
  margin: 5px 5px;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid light-dark(rgb(191, 188, 188), rgb(133, 133, 133));
}

.applicant_detail label {
  transform: translate(14px, -6px) scale(0.75);
}



.mark_details_section {
  padding: 20px 18px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5);
  border-radius: 5px;
  margin: 40px 0;
  position: relative;
}

.btn_list2 {
  position: absolute;
  top: -5%;
  left: 3%;
}

.btn_list2 button {
  background-color: #b23427;
  color: white;
  border-radius: 50px;
  padding: 1px 10px;
}

.mark_details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

}

.world_mark {
  text-decoration: underline;
  line-height: 5px;
  width: 30%;
}

.world_mark select {
  margin-left: 5px;
  border: none;
  text-decoration: underline;
}

.mark_text_area {
  width: 70%;
}
.mark_text_area textarea {
  width: 100%;
}


.mark_text_area input {
  width: 100%;
  margin: 5px -10px;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid light-dark(rgb(191, 188, 188), rgb(133, 133, 133));
}

.description {
  width: 100%;
  margin: 10px 0;
}

.description textarea {
  width: 100%;
}

.use_details_section {
  padding: 15px 25px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5);
  border-radius: 5px;
  margin: 40px 0;
  font-size: 2rem;
  position: relative;
}

.btn_list3 {
  position: absolute;
  top: -15%;
  left: 3%;
}

/* .btn_list3 {
  position: relative;
}

.btn_list3.checked {
  position: absolute;
  top: -25%;
  left: 3%;
} */

.btn_list3 button {
  background-color: #b23427;
  color: white;
  border-radius: 50px;
  padding: 1px 10px;
}

/* .use-details_div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
} */

.use_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin: 25px 0;
}

.checkmark {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
}

.date-picker {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0;
  cursor: pointer;
  text-align: left;
}

.date-picker span {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  opacity: .6;
}

/* .date_picker {
  position: absolute;
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  pointer-events: none;
} */
.date-picker {
  width: 100%;
}

.datePickerWrapper {
  width: 100%;
}
date_picker:focus {
  outline: none;
  border-bottom: 2px solid #b23427;
}

.MuiFormControl-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  padding: 0;
  position: absolute;
  pointer-events: none;
  transform: translate(0, 1.5px) scale(1);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.MuiInputLabel-animated {
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.MuiInputBase-root {
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 2em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.MuiInput-root {
  font-size: 1rem;
  line-height: 1.1876em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  cursor: text;
  box-sizing: border-box;
  align-items: center;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #b23427;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #b23427;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #b23427;
}

.MuiInput-formControl {
  margin-top: 16px;
}


.use-evidences {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.use-evidences p {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  cursor: pointer;
}

/* file upload table */

.uploaded-files-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.uploaded-files-table thead tr th {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  border: 1px solid #000;
  padding: 4px 6px;
}

.uploaded-files-table tbody tr td {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  border: 1px solid #000;
  padding: 3px 5px;

}

.uploaded-files-table th {
  background-color: #f5f5f5;
}

.uploaded-files-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.uploaded-files-table button {
  background: none;
  border: none;
  color: #f44336;
  cursor: pointer;
  font-size: 16px;
}

.uploaded-files-table button:hover {
  color: #d32f2f;
}


.Power_of_Attorney_section {
  padding: 25px 18px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 50, 5);
  border-radius: 5px;
  margin: 10px 0;
  position: relative;
}

.btn_list4 {
  position: absolute;
  top: -3.5%;
  left: 3%;
}

.btn_list4 button {
  background-color: #b23427;
  color: white;
  border-radius: 50px;
  padding: 1px 10px;
}

.link_tag {
  margin: 10px 0;
}

.link_tag a {
  color: #b23427;
  font-weight: 500;
}

.link_tag:hover {
  color: #b23427;
  ;
}

.des2 {
  margin: 20px 0;
}

.fileUpload {
  border: 1px dashed black;
  border-radius: 5px;
  text-align: center;
  padding: 1rem;
  margin: 10px 0;
  background-color: rgba(249, 250, 251);
  opacity: 1;
}

.submit_button {
  text-align: center;
  margin-top: 20px;
  border-radius: 5px;
}

.submit_button button {
  color: #000;
  background-color: #D3D3D3;
  width: auto;
  padding: 2px 10px;
  font-size: 14px;
}

.banner {
  width: 100%;
  height: 300px;
  background-image: url('../assets/img//blog/inner_banner.jpeg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

.banner-content {
  text-align: left;
  color: white;
  margin-left: 40px;
  font-size: 24px;
  font-family: "museo500";
}
.date-picker
{
  margin-bottom: 20px;
  /* width:37rem; */
  height:2rem;
  border-bottom: 1px solid black;
}
@media (max-width: 768px) {
  .wrapper {
    width: 90%;
  }
}
