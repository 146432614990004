.mycard {
  align-items: center;
  justify-content: center;
  min-width: 240px;
  max-width: 300px;
  height: 370px;
  background-color:#E7EAEB!important;
  margin:40px;
}
.mycard:hover
 {
  transform: scale(1.05);
}



