/* styles.css */

.buttonStyles {
  width: 250px;
  height: 50px;
  background: #9b945f;
  border: #9b945f;
  position: relative;
}
.image-containerbarimage
{
  height: 400px !important;
  margin-top: -40px !important;
}
.circleStyle {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #b23427;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -300px;
  margin-left: 100px;
}

.textStyles {
  font-size: 16px;
  font-family: HelveticaNeue-Roman, arial, helvetica, sans-serif;
  color: black;
  border: 1px solid black;
  text-align: center;
  padding-top: 5px;
  position: relative;
  text-decoration: none;
}
.circlestyleintership {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #b23427;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(20%, -8%);
  z-index: 1;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -300px;
  margin-left: 100px;
}

@media (max-width: 768px) {
  .mobilecareer {
    width: 100% !important;
    padding: 20px 20px !important;
  }
  .mobilecre {
    width: 100% !important;
  }
  .desmobiletext
  {
    padding-bottom: 50px !important;
  }
  .desmobiletext1
  {
    padding-top: 50px !important;
  }
  .careermobile {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 40px !important;
    margin-top: -50px !important;
  }
  .remove-on-mobile {
    display: none;
  }
  .circlestyleintership {
    position: static;
    transform: none;
    margin: 0 auto;
    text-align: center;
    width: 220px !important;
    height: 220px !important;
    margin-top: 40px !important;
  }
  .bannerimg {
    margin-top: 0px !important;
    height: 350px !important;
  }
  .bannerimgmobile {
    margin-top: -355px !important;
  }
  .Bannerlogomobile {
    height: 200px !important;
    margin-top: -50px !important;
  }
  .subheading
  {
    margin-bottom: 10px !important;
  }
}
